<!--  -->
<template>
  <div class='kaifapiao round bj-col-white pad'>
    <div class="form">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="选择年份:" prop="year">
          <!-- <el-select v-model="form.year" placeholder="选择年份" class="picker">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <div>{{form.year}}</div>
        </el-form-item>
        <el-form-item label="发票开具方式:">
          (普通发票)电子
        </el-form-item>
        <el-form-item label="发票金额:">
          180元
        </el-form-item>
        <el-form-item label="发票内容:">
          执业药师继续教育培训费
        </el-form-item>
        <el-form-item label="公司名称（不低于5个字）:" prop="name">
          <el-input v-model="form.name" placeholder="请输入营业执照上公司名全称" style="width: 250px"></el-input>
        </el-form-item>
        <br/>
        <el-form-item label="统一社会信用代码（18位）:" prop="code">
          <el-input v-model="form.code" placeholder="即开票税号" style="width: 240px" maxlength="18"></el-input>
        </el-form-item>
        <!-- <br/>
        <el-form-item label="手机号:" prop="phone">
          <el-input v-model="form.phone" placeholder="请填写手机号" style="width: 250px" maxlength="11"></el-input>
        </el-form-item> -->
        <br/>
        <el-form-item label="电子邮箱:" prop="email">
          <el-input v-model="form.email" placeholder="请填写电子邮箱" style="width: 355px" type="email"></el-input>
        </el-form-item>
        <br/>
        <el-form-item label="特殊要求:" label-width="85px">
          <el-input v-model="form.pxark" :rows="1" placeholder="备注默认填写培训人姓名。如需开地址、银行账号信息请填写此处" style="width: 498px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn">
      <el-button round type="success" class="self-button--success" style="width: 260px" @click="onSubmit">提交</el-button>
    </div>
    <div class="hint">
      发票须知：如不需要发票可不填写以上信息，如需发票请仔细核对开票信息,发票开出概不退换。<br/>提交发票信息后在5个工作日内发到指定邮箱，请注意查看！
    </div>
  </div>
</template>

<script>
import Api from '../../../api/my'

export default {
  components: {},
  data() {
    return {
      options: [],
      form: {
        year: '',
        name: '',
        code: '',
        // phone: '',
        email: '',
        pxark: '',
      },
      rules: {
        year: [
          { required: true, message: '请选择年份', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请填写公司名称', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请填写统一社会信用代码', trigger: 'blur' },
        ],
        // phone: [
        //   { required: true, message: '请填写手机号', trigger: 'blur' },
        // ],
        email: [
          { required: true, message: '请填写电子邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.form.year = this.$route.query.year
    // let userInfo = localStorage.getItem('userInfo')
    // userInfo = JSON.parse(userInfo)
    // this.form.year = userInfo.year
    // this.options = userInfo.years.map(item => {
    //   return {
    //     label: item,
    //     value: item
    //   }
    // })
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.name.length < 5) {
            this.$message.warning("请填写正确格式的公司名称")
            return false
          }
          if (this.form.code.length < 18) {
            this.$message.warning("请填写18位社会统一信用代码")
            return false
          }
          Api.openInvoice({
            year: this.form.year,
            gongsi: this.form.name,
            shibiehao: this.form.code,
            email: this.form.email,
            phone: this.form.phone,
            pxark: this.form.pxark
          }).then(res => {
            if (res.data.code == 1) {
              this.$message.success("提交成功")
              this.$emit('handle')
            } else {
              this.$message.error(res.data.msg || "提交失败")
            }
          })
        } else {
          return false
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.kaifapiao {
  .form {
    margin: 15px 0;
  }
  .btn {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .hint {
    padding: 10px;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    color: #333333;
    background-color: #E7F5FD;
  }
}
</style>